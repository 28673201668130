// import About from "./components/about";
import Home from "./components/Home";
// import Contact from "./components/contact";
// import { BrowserRouter, Routes,  Route } from "react-router-dom";



function App() {  

  return (
  <div>
      
    {/* //     <BrowserRouter> */}
    {/* // <Routes>\ */}
      
      {/* // <Route index element={    */}
      <Home/>
      {/* //  } */}
{/* // /> */}
{/* <Route path="/about" element={<About />} /> */}
{/* <Route path="/contact" element={<Contact />} /> */}
    {/* // </Routes> */}
    {/* // </BrowserRouter>        */}
    </div>
  );
}

export default App;
