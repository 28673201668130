// MapContainer.js
import React, { useEffect } from 'react';
import L from 'leaflet';
import mosqueIcon from '../assets/masjidmarkuplogo.png'; // Import the mosque icon

import 'leaflet/dist/leaflet.css';

const MapContainer = () => {
  useEffect(() => {
    // Create a map instance and specify the location and zoom level
    const map = L.map('map').setView([24.937941442263487, 67.16027568465937], 13);

    // Add a tile layer to the map using OpenStreetMap tiles
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(map);

    // Create a custom icon for the mosque marker
    const customIcon = L.icon({
      iconUrl: mosqueIcon,
      iconSize: [32, 32], // Adjust icon size if needed
      iconAnchor: [16, 32], // Adjust icon anchor if needed
      popupAnchor: [0, -32] // Adjust popup anchor if needed
    });

    // Add a marker to the map with the custom icon
    L.marker([24.938019270501982, 67.16052244786793], { icon: customIcon })
      .addTo(map)
      .bindPopup('🕌 Dar-ul-uloom Al-Arbia karachi'); // Add the mosque name or any other text
  }, []);

  return (
    <div id="map" style={{ width: '100%', height: '50vh', flex: 1 }}></div>
  );
};

export default MapContainer;
