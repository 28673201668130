import React from "react";
import "../App.css";
const About = () => {

  return (
    <div className="App">
          <div className="about">
          <h1>About Us</h1>
      <p>دارالافتاء دارالعلوم العربیہ کراچی دنیا بھر کے مسلمانوں کی خدمت کے لیے مخصوص ہے، جہاں وہ اپنی روز مرہ کی زندگی کے متعلق مسائل پوچھ کر قرآن و سنت کی روشنی میں ان پر عمل کر سکتے ہیں ۔
      </p>
      <p>DAR-UL-IFTA Dar-ul-uloom Al Arbia Karachi is dedicated to help Muslims worldwide where they can ask questions about their daily life to be compliance with Shariah</p>
      </div>
    </div>
  );
};

export default About;
