import React from 'react';
import "../App.css";
// import Book1 from "../assets/book1.jpeg"
// Dynamically import all PDF files from the pdfs directory
const pdfFiles = require.context('../assets/pdfs', false, /\.pdf$/);
const pdfFileKeys = pdfFiles.keys();
const pdfs = pdfFileKeys.map(pdfFileKey => pdfFiles(pdfFileKey));

const About = () => {
  return (
    <div className="App">
      <div className="about">
        <h1>Books Library</h1>
        <ul>
          {pdfs.map((pdf, index) => (
            <li key={index}>
              {/* Use the pdf file name as the link text */}
              <a href={pdf}>{`Book ${index + 1}`}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default About;
