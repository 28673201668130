import React from "react";
import "../App.css";

import PhoneNumberLink from "./phoneno";
const Contact = () => {
  
  return (
    <div className="App">
          <div className="about">
          <h1>Contact Us</h1>
      <p>دارالافتاء دارالعلوم العربیہ کراچی</p>
      <h2>:رابطہ نمبر</h2>
    <div>  
    <PhoneNumberLink phoneNumber="02134613689" />
      </div>
     <div> 
     <PhoneNumberLink phoneNumber=" 0310-2392853" />
     </div>
      </div>
    </div>
  );
};

export default Contact;
