import React from 'react';

function PhoneNumberLink({ phoneNumber }) {
  const handlePress = () => {
    window.open(`tel:${phoneNumber}`);
  };

  return (
    <span onClick={handlePress}>{phoneNumber}</span>
  );
}
export default PhoneNumberLink;