import "../App.css";
import header from "../assets/cover.jpg";
import admission1 from "../assets/admissions.jpeg";
import admission2 from "../assets/admissions2.jpeg";
import travel_03 from "../assets/picwithdetails.jpeg";
import Hero from "./Hero";
import Navbar from "./Navbar";
import Slider from "./Slider";
import About from "./about.js";
import Contact from "./contact";
import Library from "./library";
import Location from "./location.js";
import Hifz from "../assets/Admisson Form/hifz.pdf";
import Aalim from "../assets/Admisson Form/aalim.pdf";
import PrayerTimes from "./prayerTime.js";
const email = "Darululoomarbia06753@gmail.com";
const subject = "Ap kay Masail aur unka Hall  آپ کے مسائل اور ان کا حل";
const url = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
function Home() {
  const handleScroll = (e) => {
    e.preventDefault();
    console.log("hello");
    const targetId = e.currentTarget.getAttribute("href");
    const targetElement = document.querySelector(targetId);
    targetElement.scrollIntoView({ behavior: "smooth" });
  };
  const navbarLinks = [
    { url: "#home", title: "Home", onClick: { handleScroll } },
    { url: "#library", title: "Books Library", onClick: { handleScroll } },
    { url: "#prayer-Timings", title: "Prayers", onClick: { handleScroll } },
    { url: "#about", title: "About", onClick: { handleScroll } },
    { url: "#contact", title: "Contact", onClick: { handleScroll } },
    { url: url, title: "Online Questions", onClick: { handleScroll } },
  ];

  return (
    <div className="App">
      {/* <button onClick={handleScroll}>check</button> */}

      <Navbar
        navbarLinks={navbarLinks}
        target="_blank"
        rel="noopener noreferrer"
      />
      <section id="home">
        <Hero imageSrc={header} />
        <Slider
          imageSrc={admission1}
          imageSrc1={admission2}
          title={"Be our part."}
          subtitle={"Our platform offers a wide variety of Knowledge!"}
          hifz={"  داخلہ فارم برائے شعبہ گردان حفظ و ناظرہ"}
          admission1={Hifz}
          aalim={"داخلہ فارم برائے شعبہ کتب"}
          admission2={Aalim}
        />

        <Slider
          imageSrc={travel_03}
          title={"Our Institute"}
          subtitle={"Experienced Intellectuals & Scholars"}
          flipped={true}
        />
      </section>
      <section id="library">
        <Library />
      </section>
      <section id="prayer-Timings">
        <PrayerTimes />
      </section>
      <section id="location">
        <Location />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}

export default Home;
