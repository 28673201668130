// App.js
import React from 'react';
import MapContainer from './mapContainer';

function Location() {
  return (
    <div className="App">
    <div  className="location">
    <h1>Location</h1>
      <MapContainer />
      </div>
    </div>
  );
}

export default Location;
