import React from 'react';
import '../App.css';

function Prayer() {


  return (
    <div className="App">
      <div className="about">
        <h1>Prayer Times</h1>
        <iframe
  id="iframe"
  title="prayerWidget"
  className="widget-m-top" // change class to className
  style={{ height: "358px", border: "1px solid #ddd"}}
  scrolling="no"
  src="https://www.islamicfinder.org/prayer-widget/1174872/hanfi/3/0/18.0/18.0"
/>
    </div>
    </div>
  );
}

export default Prayer;
